// ----------------------------------------------------------------------
type TAppBar = {
  md: number;
  lg: number;
};

const appBar: TAppBar = {
  md: 64,
  lg: 92,
};

export default appBar;

declare module "@mui/material/styles" {
  interface Theme {
    appBar: TAppBar;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    appBar?: Partial<TAppBar>;
  }
}
