// ----------------------------------------------------------------------

const Container = () => ({
  MuiContainer: {
    styleOverrides: {
      root: {
        height: "100%",
      },
    },
  },
});

export default Container;
