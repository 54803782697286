import React from "react";
import { Link } from "react-router-dom";
// import MenuIcon from '@mui/icons-material/Menu';
// material
import { alpha, styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, Typography } from "@mui/material";
import Parse from "parse";
import Logo from "../../components/Logo";

// components
// import { MHidden } from '../../components/@material_extend';
//
// import Searchbar from './Searchbar';
import AccountPopover from "./AccountPopover";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 0;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: theme.appBar.md,
  [theme.breakpoints.up("lg")]: {
    minHeight: theme.appBar.lg,
    padding: theme.spacing(0, 5),
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  width: 215,
  maxWidth: "225px",
  display: "inline-block",
  [theme.breakpoints.down("sm")]: {
    margin: "0 auto",
  },
  position: "absolute",
  left: "50%",
  transform: "translateX(-50%)",
  cursor: "pointer",
}));

// ----------------------------------------------------------------------

const DashboardNavbar: React.FC<{
  onOpenSidebar: React.MouseEventHandler<HTMLButtonElement>;
}> = () => {
  const { REACT_APP_NAME, REACT_APP_COMPANY_LOGO_URL } = process.env;
  const currentUser = Parse.User.current();

  const agencyName = currentUser?.get("agency");

  return (
    <RootStyle>
      <ToolbarStyle>
        {/* <MHidden width="lgUp">
          <IconButton
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: 'text.primary' }}
          >
            <MenuIcon />
          </IconButton>
        </MHidden> */}

        {/* <Searchbar /> */}

        <Box id="navbar-page-title" />

        {["Sign&Gass", "Sign&Travel"].includes(REACT_APP_NAME!) && (
          <Box
            component="img"
            src={REACT_APP_COMPANY_LOGO_URL}
            sx={(theme) => ({
              maxHeight: 70,
              [theme.breakpoints.down("md")]: {
                maxHeight: 40,
              },
            })}
          />
        )}

        <Box sx={{ flexGrow: 1 }} />

        <StyledLink to="/">
          <Logo />
        </StyledLink>

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          {/* <LanguagePopover /> */}

          {/* <NotificationsPopover /> */}

          {agencyName && (
            <Typography variant="overline" color="primary">
              {agencyName}
            </Typography>
          )}

          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
};

export default DashboardNavbar;
