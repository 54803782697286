import React from "react";
import { Box, CircularProgress } from "@mui/material";

import type { BoxProps } from "@mui/material";

const PageLoader: React.FC<BoxProps> = (props) => (
  <Box
    sx={{
      display: "flex",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
    }}
    {...props}
  >
    <CircularProgress />
  </Box>
);

export default PageLoader;
