import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CodiceFiscale from "codice-fiscale-js";
import { Form, FormikProvider, useFormik } from "formik";
import React from "react";
import * as Yup from "yup";

import { DatePicker, LoadingButton } from "@mui/lab";
import { Slide, Stack, TextField, Zoom } from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import { useTranslation } from "react-i18next";
import { TContract } from "../../../types/Contract";
import { ICustomer } from "../../../types/Customer";
import { IEvent } from "../../../types/Event";
import { useContract } from "../../ContractContext";

import EventAutocomplete from "../../EventAutocomplete";
import { useDocumentUploadStepper } from "../DocumentsUploadStepper";

interface ICustomerInformationProps {
  children?: never;
}

const CustomerInformation: React.FC<ICustomerInformationProps> = () => {
  const { handleNext } = useDocumentUploadStepper();
  const { setContractNumber, setCustomer, setEvent } = useContract();
  const { t } = useTranslation();
  const { REACT_APP_NAME } = process.env;

  const schema: Yup.SchemaOf<
    Omit<ICustomer, "fullName"> &
      IEvent &
      Pick<TContract, "contractNumber" | "phoneNumber">
  > = Yup.object().shape({
    contractNumber: Yup.string()
      .min(2, ({ min }) => `Inserisci almeno ${min} caratteri.`)
      .notRequired()
      .nullable(),
    firstName: Yup.string()
      .min(2, ({ min }) => `Inserisci almeno ${min} caratteri.`)
      .required("Il nome del cliente è richiesto")
      .default(""),
    lastName: Yup.string()
      .min(2, ({ min }) => `Inserisci almeno ${min} caratteri.`)
      .required("Il cognome del cliente è richiesto")
      .default(""),
    place: Yup.string().notRequired().optional().nullable().default(""),
    date: Yup.date()
      .notRequired()
      .optional()
      .nullable()
      .default(new Date())
      .min(
        new Date(new Date().setHours(0, 0, 0, 0)),
        "La data non può essere precedente al giorno odierno"
      ),
    departureDate: Yup.date().notRequired().optional().default(new Date()),
    signDate: Yup.date().notRequired().optional().nullable(),
    name: Yup.string().notRequired().optional().nullable().default(""),
    fiscalCode: Yup.string()
      .min(16, "Il Codice Fiscale deve essere composto da almeno 16 caratteri")
      .max(16, "Il Codice Fiscale deve essere composto da al più 16 caratteri")
      .test("invalid-cf", "Il Codice Fiscale non è valido", (value) => {
        if (!value) {
          return false;
        }

        return CodiceFiscale.check(value);
      })
      .required("Il Codice Fiscale deve essere fornito necessariamente")
      .default(""),
    phoneNumber: Yup.string().notRequired().default(""),
  });

  const formik = useFormik<Yup.Asserts<typeof schema>>({
    initialValues: {
      contractNumber: "",
      firstName: "",
      lastName: "",
      place: "",
      name: "",
      date: new Date(),
      fiscalCode: "",
      departureDate: new Date(),
      phoneNumber: "",
      signDate: null
    },
    validationSchema: schema,
    onSubmit: async (values, { setSubmitting }) => {
      const { contractNumber, place, name, date, ...customer } = values;

      setContractNumber(contractNumber);

      if (place && name) {
        setEvent({ place, name, date });
      }

      setCustomer({
        ...customer,
        fiscalCode: customer.fiscalCode.toUpperCase(),
        fullName: [customer.firstName, customer.lastName]
          .filter((v) => v)
          .join(" "),
        departureDate: customer.departureDate,
        signDate: customer.signDate,
      });
      setSubmitting(false);
      handleNext();
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    isValid,
    dirty,
    values,
    setFieldValue,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} mt={5}>
          {REACT_APP_NAME !== "Sign&Gass" && (
            <Zoom
              in
              timeout={{
                enter: 500,
              }}
            >
              <TextField
                fullWidth
                label={t("contractNumber")}
                {...getFieldProps("contractNumber")}
                error={Boolean(touched.contractNumber && errors.contractNumber)}
                helperText={touched.contractNumber && errors.contractNumber}
              />
            </Zoom>
          )}

          <Stack spacing={5} direction="row">
            <Zoom
              in
              timeout={{
                enter: 600,
              }}
            >
              <TextField
                fullWidth
                autoComplete="firstName"
                label={t("firstName")}
                {...getFieldProps("firstName")}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />
            </Zoom>

            <Zoom
              in
              timeout={{
                enter: 700,
              }}
            >
              <TextField
                fullWidth
                autoComplete="lastName"
                label={t("lastName")}
                {...getFieldProps("lastName")}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
            </Zoom>

            <Zoom
              in
              timeout={{
                enter: 800,
              }}
            >
              <TextField
                fullWidth
                label={t("fiscalCode")}
                {...getFieldProps("fiscalCode")}
                error={Boolean(touched.fiscalCode && errors.fiscalCode)}
                helperText={touched.fiscalCode && errors.fiscalCode}
                inputProps={{
                  style: {
                    textTransform: "uppercase",
                  },
                  pattern: "[A-Za-z0-9]{16}",
                  maxLength: 16,
                  minLength: 16,
                }}
              />
            </Zoom>
          </Stack>

          {REACT_APP_NAME === "Sign&Travel" && (
            <Stack direction="row" spacing={5}>
              <Zoom
                in
                timeout={{
                  enter: 900,
                }}
              >
                <DatePicker
                  label={t("departureDate")}
                  inputFormat="dd/MM/yyyy"
                  renderInput={(parameters) => (
                    <TextField
                      {...parameters}
                      {...getFieldProps("departureDate")}
                      fullWidth
                      error={Boolean(
                        touched.departureDate && errors.departureDate
                      )}
                      helperText={touched.departureDate && errors.departureDate}
                    />
                  )}
                  minDate={new Date()}
                  value={values.departureDate!}
                  onChange={(value) => setFieldValue("departureDate", value)}
                />
              </Zoom>

              <Zoom
                in
                timeout={{
                  enter: 1100,
                }}
              >
                <DatePicker
                  label={t("signDate")}
                  inputFormat="dd/MM/yyyy"
                  renderInput={(parameters) => (
                    <TextField
                      {...parameters}
                      {...getFieldProps("signDate")}
                      fullWidth
                    />
                  )}
                  value={values.signDate}
                  disablePast
                  clearable
                  onChange={(value) => setFieldValue("signDate", value)}
                />
              </Zoom>
            </Stack>
          )}

          {REACT_APP_NAME === "Sign&Gass" && (
            <Stack spacing={5} direction="row">
              <Zoom
                in
                timeout={{
                  enter: 700,
                }}
              >
                <TextField
                  fullWidth
                  label={t("place")}
                  {...getFieldProps("place")}
                  error={Boolean(touched.place && errors.place)}
                  helperText={touched.place && errors.place}
                />
              </Zoom>

              <Zoom
                in
                timeout={{
                  enter: 800,
                }}
              >
                <EventAutocomplete
                  fullWidth
                  label={t("eventName")}
                  {...getFieldProps("name")}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  onSelect={({ target }) => {
                    // @ts-ignore
                    setFieldValue("name", target.value);
                  }}
                />
              </Zoom>

              <Zoom
                in
                timeout={{
                  enter: 900,
                }}
              >
                <DatePicker
                  label={t("eventDate")}
                  inputFormat="dd/MM/yyyy"
                  renderInput={(parameters) => (
                    <TextField
                      {...parameters}
                      {...getFieldProps("date")}
                      fullWidth
                      error={Boolean(touched.date && errors.date)}
                      helperText={touched.date && errors.date}
                    />
                  )}
                  minDate={new Date()}
                  value={values.date}
                  onChange={(value) => setFieldValue("date", value)}
                />
              </Zoom>
            </Stack>
          )}

          {REACT_APP_NAME === "Sign&Travel" && (
            <Stack spacing={5} direction="row" sx={{ minWidth: 0 }}>
              <Zoom
                in
                timeout={{
                  enter: 900,
                }}
              >
                <div>
                  <MuiPhoneNumber
                    fullWidth
                    variant="outlined"
                    defaultCountry={"it"}
                    disableAreaCodes
                    value={values.phoneNumber}
                    onChange={(value) => setFieldValue("phoneNumber", value)}
                    sx={{
                      svg: {
                        maxHeight: "20px",
                        height: "100%",
                      },
                    }}
                  />
                </div>
              </Zoom>
            </Stack>
          )}

          <Slide direction="up" in={isValid && dirty}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              disabled={dirty && !isValid}
              loading={isSubmitting}
              endIcon={<NavigateNextIcon />}
              variant="contained"
            >
              {t("nextStep")}
            </LoadingButton>
          </Slide>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default CustomerInformation;
