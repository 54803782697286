// ----------------------------------------------------------------------
import type { Theme } from "@mui/material";

const Fab = (theme: Theme) => ({
  MuiFab: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        "&:hover": {
          backgroundColor: theme.palette.primary.light,
        },
      },
    },
  },
});

export default Fab;
