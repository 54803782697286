type AppName =
  | "FirmoSubito"
  | "InstaSign"
  | "LeggeScuola"
  | "Sign&Gass"
  | "Sign&Travel";

const useAppName = () => {
  const appName = process.env.REACT_APP_NAME! as AppName;

  return appName;
};

export default useAppName;
