import { Paper, Zoom } from "@mui/material";
import React from "react";

import Parse from "parse";

import { useAsync, useToggle } from "react-use";
import { useContract } from "../../ContractContext";
import SendSmsDialog from "../../SendSmsDialog";
import ShareContract from "../../ShareContract";
import { useDocumentUploadStepper } from "../DocumentsUploadStepper";

interface ISendDocumentsProps {
  children?: never;
}

const SendDocuments: React.FC<ISendDocumentsProps> = () => {
  const { resetStepper } = useDocumentUploadStepper();
  const { customer, contractNumber, event, savedFiles } = useContract();

  const [isSmsModalVisible, toggleSmsModalVisibility] = useToggle(false);

  const { loading: isCreatingContract, value: contract } = useAsync<
    () => Promise<Parse.Object<Parse.Attributes>>
  >(async () => {
    const contracts = new Parse.Object(
      process.env.REACT_APP_CONTRACT_TABLE_NAME
    );

    contracts.set("contractNumber", contractNumber);

    contracts.set(process.env.REACT_APP_FILES_TO_SIGN_COLUMN_NAME!, savedFiles);

    contracts.set("user", Parse.User.current());

    contracts.set("customer", customer);

    // @ts-ignore
    contracts.set("phoneNumber", customer.phoneNumber);

    if (event) {
      contracts.set("event", event);
    }

    await contracts.save();

    return contracts;
  }, [contractNumber, customer, event, savedFiles]);

  return (
    <>
      <Zoom in>
        <Paper
          elevation={6}
          sx={{
            padding: 2,
            width: "100%",
            marginTop: 2,
          }}
        >
          <ShareContract
            isCreatingContract={isCreatingContract}
            contract={contract}
            customer={customer}
            resetStepper={resetStepper}
            toggleSmsModalVisibility={toggleSmsModalVisibility}
          />
        </Paper>
      </Zoom>

      <SendSmsDialog
        open={isSmsModalVisible}
        contract={contract}
        onClose={toggleSmsModalVisibility}
      />
    </>
  );
};

export default SendDocuments;
