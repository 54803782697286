// material
import { CssBaseline } from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material/styles";
//
import { itIT } from "@mui/x-data-grid";
import React from "react";
import shape from "./shape";
import palette from "./palette";
import typography from "./typography";
import componentsOverride from "./overrides";
import shadows, { customShadows } from "./shadows";
import appBar from "./appBar";

// ----------------------------------------------------------------------

const ThemeConfig: React.FC = ({ children }) => {
  const themeOptions = React.useMemo(
    () => ({
      palette,
      shape,
      typography,
      shadows,
      customShadows,
      appBar,
    }),
    []
  );

  const theme = createTheme(themeOptions, itIT);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeConfig;
