import LoginIcon from "@mui/icons-material/Login";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { Form, FormikProvider, useFormik } from "formik";
import Parse from "parse";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

// material
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  IconButton,
  InputAdornment,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useToggle } from "react-use";
import { User } from "../../types/User";
import ResetPasswordDialog from "../ResetPasswordDialog";

// ----------------------------------------------------------------------

const LoginForm: React.FC = () => {
  const { t } = useTranslation();

  const [isLoginErrorVisible, setLoginErrorVisibility] =
    React.useState<boolean>(false);

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [isResetPasswordDialogVisibile, toggleResetPasswordDialogVisibility] =
    useToggle(false);

  const schema: Yup.SchemaOf<Pick<User, "username" | "password">> =
    Yup.object().shape({
      username: Yup.string().required("Devi inserire il tuo username"),
      password: Yup.string().required("Inserisci una password"),
    });

  const formik = useFormik<Yup.Asserts<typeof schema>>({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: schema,
    onSubmit: async ({ username, password }, { setSubmitting }) => {
      try {
        await Parse.User.logIn(username, password);

        navigate("/dashboard", { replace: true });
      } catch {
        setLoginErrorVisibility(true);
        setSubmitting(false);
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleLoginErrorClose = () => {
    setLoginErrorVisibility((prev) => !prev);
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="username"
              label="Username"
              {...getFieldProps("username")}
              error={Boolean(touched.username && errors.username)}
              helperText={touched.username && errors.username}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label="Password"
              {...getFieldProps("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />

            <Button
              variant="text"
              sx={{
                marginTop: "0px !important",
                width: "max-content",
                alignSelf: "self-end",
              }}
              onClick={toggleResetPasswordDialogVisibility}
            >
              {t("forgotPassword")}
            </Button>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              endIcon={<LoginIcon />}
            >
              Login
            </LoadingButton>
          </Stack>

          {/* <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  {...getFieldProps('remember')}
                  checked={values.remember}
                />
              }
              label="Remember me"
            />

            <Link component={RouterLink} variant="subtitle2" to="#">
              Forgot password?
            </Link>
          </Stack> */}
        </Form>
      </FormikProvider>
      <Snackbar
        open={isLoginErrorVisible}
        autoHideDuration={6000}
        onClose={handleLoginErrorClose}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <Alert
          onClose={handleLoginErrorClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Errore durante l'accesso
        </Alert>
      </Snackbar>

      <ResetPasswordDialog
        open={isResetPasswordDialogVisibile}
        onClose={toggleResetPasswordDialogVisibility}
      />
    </>
  );
};

export default LoginForm;
