import { Outlet } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
//
import DashboardNavbar from "./DashboardNavbar";

// ----------------------------------------------------------------------

const RootStyle = styled("div")({
  display: "flex",
  flexDirection: "column",
  minHeight: "100%",
  overflow: "hidden",
  position: "relative",
});

const MainStyle = styled("main")(() => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  height: "100vh",
}));

const StyledLogo = styled("img")(({ theme }) => ({
  maxHeight: 45,
  height: "100%",
  width: 150,
  position: "absolute",
  left: 20,
  bottom: 20,
  [theme.breakpoints.down("sm")]: {
    visibility: "hidden",
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { REACT_APP_CYBERNEID_LOGO_URL } = process.env;

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => {}} />

      <MainStyle>
        <Outlet />
      </MainStyle>

      <StyledLogo alt="" src={REACT_APP_CYBERNEID_LOGO_URL} />
    </RootStyle>
  );
}
