import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Button, Slide, Stack, Zoom } from "@mui/material";
import { useSnackbar } from "notistack";
import Parse from "parse";
import React from "react";
import { useTranslation } from "react-i18next";
import { useContract } from "../../ContractContext";
import DocumentUploader from "../../DocumentUploader";
import { useDocumentUploadStepper } from "../DocumentsUploadStepper";

interface IUploadDocumentsProps {
  children?: never;
}

const UploadDocuments: React.FC<IUploadDocumentsProps> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { handleNext } = useDocumentUploadStepper();
  const { files, setFiles, setSavedFiles } = useContract();
  const { t } = useTranslation();

  const [areFilesUploaded, setFilesUploaded] = React.useState<boolean>(false);

  const handleNextStepButtonVisibility = React.useMemo(
    () =>
      areFilesUploaded &&
      files.length > 0 &&
      !files.some((file) => file.status === 8),
    [areFilesUploaded, files]
  );

  return (
    <Zoom in>
      <Stack spacing={2} mt={2}>
        <DocumentUploader
          // @ts-ignore
          files={files}
          onupdatefiles={(updatedFiles) => {
            setFiles(updatedFiles);
          }}
          onaddfilestart={(addedFile) => {
            if (addedFile.status !== 8) {
              setFilesUploaded(false);
            }
          }}
          onprocessfiles={() => {
            setFilesUploaded(true);
          }}
          onremovefile={() => {
            setFilesUploaded(true);
          }}
          server={{
            process: async (_, file, __, load) => {
              try {
                const savedFile = await new Parse.File(
                  file.name
                    .replaceAll("(", "_")
                    .replaceAll(")", "")
                    .replaceAll("®", ""),
                  file,
                  file.type
                ).save({
                  progress: (
                    progressValue: string,
                    _loaded: any,
                    _total: any,
                    { type }: any
                  ) => {
                    if (type === "upload" && progressValue !== null) {
                      // Update the UI using progressValue
                      load(progressValue);
                    }
                  },
                });

                setSavedFiles((prevFiles) => [...prevFiles, savedFile]);
              } catch (error) {
                setFiles((prevFiles) =>
                  prevFiles.filter(
                    ({ file: prevFile }) => prevFile.name !== file.name
                  )
                );

                enqueueSnackbar(
                  // @ts-ignore
                  error.toString(),
                  {
                    variant: "error",
                  }
                );
                // eslint-disable-next-line no-console
                console.error(error);
              }
            },
          }}
          fileNamesToIgnore={files.map((file) => file.filename)}
        />

        <Slide direction="up" in={handleNextStepButtonVisibility}>
          <Button
            fullWidth
            size="large"
            endIcon={<NavigateNextIcon />}
            variant="contained"
            onClick={handleNext}
          >
            {t("nextStep")}
          </Button>
        </Slide>
      </Stack>
    </Zoom>
  );
};

export default UploadDocuments;
