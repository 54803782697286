import SmsIcon from "@mui/icons-material/Sms";
import MuiPhoneNumber from "material-ui-phone-number";
import Parse from "parse";
import React, { useEffect } from "react";

import {
  Avatar,
  Dialog,
  DialogContent,
  Stack,
  styled,
  Typography,
} from "@mui/material";

import SendToMobileIcon from "@mui/icons-material/SendToMobile";
import { LoadingButton } from "@mui/lab";
import type { DialogProps } from "@mui/material";
import type { MuiPhoneNumberProps } from "material-ui-phone-number";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useAsyncFn } from "react-use";
import useAppName from "../hooks/useAppName";
import { useContract } from "./ContractContext";

const StyledDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": { width: "100%", maxHeight: 635 },
}));

interface ISendSmsDialogProps extends DialogProps {
  contract?: Parse.Object<Parse.Attributes>;
}

const SendSmsDialog: React.FC<ISendSmsDialogProps> = ({
  open,
  onClose,
  contract,
  ...other
}) => {
  const { t } = useTranslation();
  const appName = useAppName();
  const { customer } = useContract();
  const { enqueueSnackbar } = useSnackbar();

  const [mobileNumber, setMobileNumber] = React.useState<string>(
    () => contract?.get("phoneNumber") || ""
  );

  useEffect(() => {
    if (contract) {
      setMobileNumber(contract.get("phoneNumber"));
    }
  }, [contract])

  const handleMobileNumberChange: MuiPhoneNumberProps["onChange"] = (value) => {
    setMobileNumber(value as unknown as string);
  };

  const [{ loading: isSendingSms }, sendSms] = useAsyncFn(async () => {
    if (contract && mobileNumber) {
      // if (appName === "Sign&Travel") {
        await Parse.Cloud.run("sendSms", {
          phoneNumber: mobileNumber,
          messageText: t("smsText", {
            fullName: customer?.fullName || contract.get("customer")?.fullName,
            agencyName: Parse.User.current()?.get("agency"),
            url: `${window.location.origin}/sign?oid=${contract.id}`,
          }),
        });

        contract.set("phoneNumber", mobileNumber);

        await contract.save();

        enqueueSnackbar(t("smsCorrectlySent"), {
          variant: "success",
        });

        // @ts-ignore
        onClose?.();
      // }
      // else {
      //   const {
      //     REACT_APP_CLICKATELL_UN,
      //     REACT_APP_CLICKATELL_PW,
      //     REACT_APP_CLICKATELL_ID,
      //   } = process.env;

      //   // eslint-disable-next-line max-len
      //   const path = `https://api.clickatell.com/http/sendmsg?user=${REACT_APP_CLICKATELL_UN}&password=${REACT_APP_CLICKATELL_PW}&api_id=${REACT_APP_CLICKATELL_ID}&to=${mobileNumber}&text=${encodeURIComponent(
      //     t("smsText", {
      //       fullName: customer?.fullName,
      //       agencyName: Parse.User.current()?.get("agency"),
      //       url: `${window.location.origin}/sign?oid=${contract.id}`,
      //     }) as string
      //   )}`;

      //   await fetch(path);

      //   contract.set("phoneNumber", mobileNumber);

      //   await contract.save();

      //   enqueueSnackbar(t("smsCorrectlySent"), {
      //     variant: "success",
      //   });

      //   // @ts-ignore
      //   onClose?.();
      // }
    }
  }, [mobileNumber, contract, t]);

  const handleClose = React.useCallback(() => {
    // @ts-ignore
    onClose?.();
  }, [onClose]);

  return (
    <StyledDialog
      maxWidth="sm"
      fullWidth
      open={open}
      {...other}
      onBackdropClick={handleClose}
    >
      <DialogContent dividers>
        <Stack spacing={4} alignItems="center" mt={4}>
          <Stack spacing={4} alignItems="center">
            <Stack spacing={1} alignItems="center">
              <Avatar
                sx={{
                  backgroundColor: "primary.main",
                }}
              >
                <SmsIcon />
              </Avatar>

              <Typography variant="subtitle1">
                {t("sendViaSmsInstruction.title")}
              </Typography>

              <Typography variant="body1">
                {t("sendViaSmsInstruction.description")}
              </Typography>
            </Stack>

            <MuiPhoneNumber
              defaultCountry={"it"}
              disableAreaCodes
              value={mobileNumber}
              onChange={handleMobileNumberChange}
              sx={{
                svg: {
                  maxHeight: "20px",
                  height: "100%",
                },
              }}
            />

            <LoadingButton
              disabled={mobileNumber?.length === 0}
              onClick={sendSms}
              variant="contained"
              loading={isSendingSms}
              endIcon={<SendToMobileIcon />}
            >
              {t("sendDocuments")}
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogContent>
    </StyledDialog>
  );
};

export default SendSmsDialog;
