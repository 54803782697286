import { Navigate, useRoutes } from "react-router-dom";
import Parse from "parse";
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
import { Documents, Login, DocumentDetails } from "./pages";

// ----------------------------------------------------------------------

export default function Router() {
  const isLoggedIn = Parse.User.current();

  return useRoutes([
    {
      path: "/",
      element: isLoggedIn ? (
        <DashboardLayout />
      ) : (
        <Navigate to="/auth/login" replace />
      ),
      children: [
        {
          index: true,
          element: <Documents />,
        },
        {
          path: "contracts/:id",
          element: <DocumentDetails />,
        },
      ],
    },
    {
      path: "/auth",
      element: !isLoggedIn ? <LogoOnlyLayout /> : <Navigate to="/" replace />,
      children: [
        { index: true, path: "login", element: <Login /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "*", element: <Navigate to="/" replace /> },
  ]);
}
