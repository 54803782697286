import AddIcon from "@mui/icons-material/Add";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import {
  Avatar,
  Dialog,
  DialogContent,
  Stack,
  styled,
  Typography,
  Zoom,
} from "@mui/material";
import Parse from "parse";
import React from "react";

import { LoadingButton } from "@mui/lab";
import type { DialogProps } from "@mui/material";
import { FilePond } from "filepond";
import { useTranslation } from "react-i18next";
import DocumentUploader from "./DocumentUploader";

const StyledDialog = styled(Dialog)({});

interface IContractSendDialog extends DialogProps {
  contract: Parse.Object<Parse.Attributes>;
  onNewFilesUploaded: () => Promise<void>;
  fileNamesToIgnore: string[];
}

const DocumentsUploadDialog: React.FC<IContractSendDialog> = ({
  open,
  onClose,
  contract,
  onNewFilesUploaded,
  fileNamesToIgnore,
  ...other
}) => {
  const filePondRef = React.useRef<FilePond | undefined>(null);

  const { t } = useTranslation();

  const [numberOfNewDocuments, setNumberOfNewDocuments] =
    React.useState<number>(0);

  const [isUploadInProgress, setIsUploadInProgress] =
    React.useState<boolean>(false);

  const handleClose = React.useCallback(() => {
    if (!isUploadInProgress) {
      // @ts-ignore
      onClose?.();
    }
  }, [onClose, isUploadInProgress]);

  React.useEffect(() => {
    if (open) {
      setIsUploadInProgress(false);
      setNumberOfNewDocuments(0);
    }
  }, [open]);

  return (
    <StyledDialog
      maxWidth="sm"
      fullWidth
      open={open}
      {...other}
      onBackdropClick={handleClose}
    >
      <DialogContent dividers>
        <Stack spacing={4}>
          <Stack spacing={1} alignItems="center">
            <Avatar
              sx={{
                backgroundColor: "primary.main",
              }}
            >
              <UploadFileIcon />
            </Avatar>

            <Typography variant="subtitle1">{t("uploadNewFiles")}</Typography>
          </Stack>

          <DocumentUploader
            // @ts-ignore
            ref={filePondRef}
            dropValidation
            acceptedFileTypes={["application/pdf"]}
            instantUpload={false}
            server={{
              process: async (_, file, __, load) => {
                try {
                  const newFile = await new Parse.File(
                    file.name,
                    file,
                    file.type
                  ).save({
                    // @ts-ignore
                    progress: (progressValue, _loaded, _total, { type }) => {
                      if (type === "upload" && progressValue !== null) {
                        // Update the UI using progressValue
                        load(progressValue);
                      }
                    },
                  });

                  contract.add(
                    process.env.REACT_APP_FILES_TO_SIGN_COLUMN_NAME!,
                    newFile
                  );

                  await contract.save();
                } catch (error) {
                  // eslint-disable-next-line no-console
                  console.error(error);
                }
              },
            }}
            itemInsertLocation="after"
            allowProcess={false}
            allowRevert={false}
            onprocessfilestart={() => setIsUploadInProgress(true)}
            onprocessfiles={async () => {
              setIsUploadInProgress(false);
              setNumberOfNewDocuments(0);

              await onNewFilesUploaded();
              // @ts-ignore
              onClose?.();
            }}
            onupdatefiles={(files) => {
              setNumberOfNewDocuments(files.length);
            }}
            fileNamesToIgnore={fileNamesToIgnore}
          />

          <Zoom in={numberOfNewDocuments > 0}>
            <LoadingButton
              disabled={numberOfNewDocuments === 0}
              loading={isUploadInProgress}
              startIcon={<AddIcon />}
              variant="contained"
              onClick={() => filePondRef.current?.processFiles()}
            >
              {t("addNewDocumentsToContract", {
                numberOfDocuments: numberOfNewDocuments,
              })}
            </LoadingButton>
          </Zoom>
        </Stack>
      </DialogContent>
    </StyledDialog>
  );
};

export default DocumentsUploadDialog;
