// import { Icon } from '@iconify/react';
import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import { LoadingButton } from "@mui/lab";
// material
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
// components
//
import LockResetIcon from "@mui/icons-material/LockReset";
import LogoutIcon from "@mui/icons-material/Logout";
import { useSnackbar } from "notistack";
import Parse from "parse";
import { useTranslation } from "react-i18next";
import MenuPopover from "../../components/MenuPopover";

// ----------------------------------------------------------------------

const MENU_OPTIONS: { label: string; icon?: string; linkTo: string }[] = [
  // {
  //   label: 'Home',
  //   // icon: homeFill,
  //   linkTo: '/',
  // },
  // {
  //   label: 'Profile',
  //   // icon: personFill,
  //   linkTo: '/me',
  // },
  // {
  //   label: 'Settings',
  //   // icon: settings2Fill,
  //   linkTo: '#',
  // },
];

// ----------------------------------------------------------------------

const AccountPopover: React.FC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const user = Parse.User.current();
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState<boolean>(false);
  const [isLoggingOut, setLoggingOut] = React.useState<boolean>(false);

  const userInitials = user
    ?.get("email")
    .split("@")[0]
    .split(".")
    .map((str: string) => str[0].toUpperCase())
    .join("");

  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      setLoggingOut(true);
      await Parse.User.logOut();
      navigate("/auth/login", { replace: true });
    } finally {
      setLoggingOut(false);
    }
  };

  const handleResetPassword = async () => {
    try {
      if (user) {
        await Parse.User.requestPasswordReset(user.getEmail()!);

        enqueueSnackbar(t("requestResetPasswordSuccess"), {
          variant: "success",
        });

        handleClose();

        await handleLogout();
      }
    } catch (error) {
      enqueueSnackbar(t("requestResetPasswordError"), {
        variant: "error",
      });
    }
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <Avatar src={user?.get("photoURL")} alt={userInitials}>
          {userInitials}
        </Avatar>
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {user?.get("email")}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: "body2", py: 1, px: 2.5 }}
          >
            <Box
              // component={Icon}
              // icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <MenuItem
          onClick={handleResetPassword}
          sx={{ typography: "body2", py: 1, px: 2.5 }}
        >
          <Box
            component={LockResetIcon}
            sx={{
              mr: 2,
              width: 24,
              height: 24,
            }}
          />
          {t("resetPassword")}
        </MenuItem>

        <Box sx={{ p: 2, pt: 1.5 }}>
          <LoadingButton
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={handleLogout}
            endIcon={<LogoutIcon />}
            loading={isLoggingOut}
          >
            {t("logout")}
          </LoadingButton>
        </Box>
      </MenuPopover>
    </>
  );
};

export default AccountPopover;
