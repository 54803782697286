import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import LockResetIcon from "@mui/icons-material/LockReset";
import Parse from "parse";
import React from "react";

import {
  Avatar,
  Dialog,
  DialogContent,
  DialogProps,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useAsyncFn } from "react-use";

const ResetPasswordDialog: React.FC<DialogProps> = ({
  open,
  onClose,
  ...other
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [email, setEmail] = React.useState<string>("");

  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = ({
    currentTarget: { value },
  }) => {
    setEmail(value);
  };

  const handleClose = React.useCallback(() => {
    setEmail("");
    // @ts-ignore
    onClose?.();
  }, [onClose]);

  const [{ loading: isResettingPassword }, handleResetPassword] =
    useAsyncFn(async () => {
      try {
        if (email) {
          await Parse.User.requestPasswordReset(email);

          enqueueSnackbar(t("requestResetPasswordSuccess"), {
            variant: "success",
          });

          handleClose();
        }
      } catch (error) {
        enqueueSnackbar(t("requestResetPasswordError"), {
          variant: "error",
        });
      }
    }, [email]);

  return (
    <Dialog maxWidth="sm" open={open} {...other} onBackdropClick={handleClose}>
      <DialogContent dividers>
        <Stack spacing={4} alignItems="center" mt={4}>
          <Stack spacing={4} alignItems="center">
            <Stack spacing={1} alignItems="center">
              <Avatar
                sx={{
                  backgroundColor: "primary.main",
                }}
              >
                <LockResetIcon />
              </Avatar>

              <Typography variant="subtitle1">{t("resetPassword")}</Typography>

              <Typography variant="body1">
                {t("resetPasswordInstruction")}
              </Typography>
            </Stack>

            <TextField
              fullWidth
              autoComplete="username"
              label="Email"
              value={email}
              onChange={handleEmailChange}
            />

            <LoadingButton
              disabled={email.length === 0}
              onClick={handleResetPassword}
              variant="contained"
              loading={isResettingPassword}
              endIcon={<ForwardToInboxIcon />}
            >
              {t("resetPassword")}
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default ResetPasswordDialog;
