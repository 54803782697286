import CalendarIcon from "@mui/icons-material/CalendarTodayOutlined";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import EventIcon from "@mui/icons-material/Event";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import ShareIcon from "@mui/icons-material/Share";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import Parse from "parse";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useToggle } from "react-use";

import type { CardProps } from "@mui/material";
import useAppName from "../hooks/useAppName";
import type { TContract } from "../types/Contract";
import ShareContractDialog from "./ShareContractDialog";
import SendSmsDialog from "./SendSmsDialog";

interface IContractCard extends CardProps {
  contract: Parse.Object<TContract>;
}

const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  maxHeight: 590,
  scrollSnapAlign: "start",
  margin: "0 auto",
  [theme.breakpoints.down("md")]: {
    maxWidth: "unset",
    margin: "0 28px 0 0",
  },
  [theme.breakpoints.up("md")]: {
    maxWidth: 350,
  },
}));

const ContractCard: React.FC<IContractCard> = ({ contract, ...props }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const appName = useAppName();

  const [isSmsModalVisible, toggleSmsModalVisibility] = useToggle(false);
  const [isShareContractDialogVisible, seSendSmsDialogVisibility] =
    React.useState<boolean>(false);

  const handleShareContractDialogVisibility = () => {
    seSendSmsDialogVisibility((prev) => !prev);
  };

  const numberOfDocumentsSigned = React.useRef<number>(
    contract.get("signed")?.length || 0
  );

  const getSignatureStatus = (): string => {
    if (numberOfDocumentsSigned.current) {
      if (numberOfDocumentsSigned.current === contract.get("toSign").length) {
        return t("allDocumentsSigned");
      }
      return t("someDocumentsSigned");
    }
    return t("noDocumentSigned");
  };

  const getContractDate = () => {
    switch (appName) {
      case "Sign&Gass":
        return contract?.get("event").date;

      case "Sign&Travel":
        return contract?.get("customer").departureDate;

      default:
        return contract?.get("createdAt");
    }
  };

  return (
    <>
      <StyledCard {...props}>
        <CardActionArea
          onClick={() => {
            navigate(`/contracts/${contract.id}`);
          }}
        >
          <CardContent>
            <Stack spacing={2}>
              <Box>
                <Typography variant="h5" component="div" color="primary.dark">
                  {appName === "Sign&Gass"
                    ? t("eventInfo", {
                        eventName: contract.get("event")?.name,
                      })
                    : t("contractTitle", {
                        contractNumber: contract.get("contractNumber") || "",
                      })}
                </Typography>
              </Box>

              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                  marginLeft: "-16px !important",
                }}
              >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        backgroundColor: "primary.main",
                      }}
                    >
                      <EventIcon />
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    primary={
                      <Stack alignItems="center" direction="row">
                        {t("contractCreationDate.label")}
                        <Tooltip
                          title={t("contractCreationDate.tooltip") as string}
                          enterDelay={750}
                          placement="right"
                        >
                          <HelpOutlineIcon
                            sx={{
                              color: "primary.main",
                              fontSize: "initial",
                              marginLeft: "4px",
                            }}
                          />
                        </Tooltip>
                      </Stack>
                    }
                    secondary={new Intl.DateTimeFormat("it-IT").format(
                      getContractDate()!
                    )}
                  />
                </ListItem>

                {appName === "Sign&Travel" && (
                  <>
                    <Divider variant="inset" component="li" />

                    <ListItem>
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            backgroundColor: "primary.main",
                          }}
                        >
                          <CalendarIcon />
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText
                        primary={
                          <Stack alignItems="center" direction="row">
                            {t("signDate")}
                          </Stack>
                        }
                        secondary={contract.get("customer")?.signDate ? new Intl.DateTimeFormat("it-IT").format(
                          contract.get("customer")?.signDate!) : "N/D"
                        }
                      />
                    </ListItem>
                  </>
                )}

                <Divider variant="inset" component="li" />

                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        backgroundColor: "primary.main",
                      }}
                    >
                      <PhoneIphoneIcon />
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    primary={
                      <Stack alignItems="center" direction="row">
                        {t("contractPhoneNumber.label")}
                        <Tooltip
                          title={t("contractPhoneNumber.tooltip") as string}
                          enterDelay={750}
                          placement="right"
                        >
                          <HelpOutlineIcon
                            sx={{
                              color: "primary.main",
                              fontSize: "initial",
                              marginLeft: "4px",
                            }}
                          />
                        </Tooltip>
                      </Stack>
                    }
                    secondary={contract.get("phoneNumber")}
                  />
                </ListItem>

                <Divider variant="inset" component="li" />

                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        backgroundColor: "#ED9E31",
                      }}
                    >
                      <InsertDriveFileIcon />
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    primary={
                      <Stack alignItems="center" direction="row">
                        {t("numberOfDocuments.label")}
                        <Tooltip
                          title={t("numberOfDocuments.tooltip") as string}
                          enterDelay={750}
                          placement="right"
                        >
                          <HelpOutlineIcon
                            sx={{
                              color: "#ED9E31",
                              fontSize: "initial",
                              marginLeft: "4px",
                            }}
                          />
                        </Tooltip>
                      </Stack>
                    }
                    secondary={contract.get("toSign").length}
                  />
                </ListItem>

                <Divider variant="inset" component="li" />

                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        backgroundColor: "#B23D82",
                      }}
                    >
                      <DriveFileRenameOutlineIcon />
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    primary={
                      <Stack alignItems="center" direction="row">
                        {t("contractSignatureStatus.label")}
                        <Tooltip
                          title={t("contractSignatureStatus.tooltip") as string}
                          enterDelay={750}
                          placement="right"
                        >
                          <HelpOutlineIcon
                            sx={{
                              color: "#B23D82",
                              fontSize: "initial",
                              marginLeft: "4px",
                            }}
                          />
                        </Tooltip>
                      </Stack>
                    }
                    secondary={getSignatureStatus()}
                  />
                </ListItem>

                <Divider variant="inset" component="li" />

                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        backgroundColor: "#B23D82",
                      }}
                    >
                      <PersonIcon />
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    primary={
                      <Stack alignItems="center" direction="row">
                        {t("contractCustomer.label")}
                      </Stack>
                    }
                    secondary={[contract.get("customer")?.fullName]}
                  />
                </ListItem>
              </List>
            </Stack>
          </CardContent>
        </CardActionArea>

        <CardActions>
          <Button
            size="small"
            color="primary"
            startIcon={<ShareIcon />}
            disabled={numberOfDocumentsSigned.current > 0}
            onClick={handleShareContractDialogVisibility}
          >
            {t("shareContract")}
          </Button>
        </CardActions>
      </StyledCard>

      <ShareContractDialog
        open={isShareContractDialogVisible}
        onClose={handleShareContractDialogVisibility}
        contract={contract}
        customer={contract.get("customer")}
        toggleSmsModalVisibility={toggleSmsModalVisibility}
      />
      
      <SendSmsDialog
        open={isSmsModalVisible}
        contract={contract}
        onClose={toggleSmsModalVisibility}
      />
    </>
  );
};

export default ContractCard;
