// ----------------------------------------------------------------------

const shape = {
  borderRadius: 8,
  borderRadiusSm: 12,
  borderRadiusMd: 16,
};

export default shape;

declare module "@mui/material/styles" {
  type TShape = {
    borderRadius: number;
    borderRadiusSm: number;
    borderRadiusMd: number;
  };

  interface Theme {
    shape: TShape;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    shape?: Partial<TShape>;
  }
}
