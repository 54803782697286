import { useSnackbar } from "notistack";
import React from "react";
import type { FilePondProps } from "react-filepond";
import { FilePond } from "react-filepond";
import { useTranslation } from "react-i18next";

interface IDocumentUploaderProps extends FilePondProps {
  fileNamesToIgnore?: string[];
}

const DocumentUploader = React.forwardRef<FilePond, IDocumentUploaderProps>(
  ({ fileNamesToIgnore, ...props }, ref) => {
    const { enqueueSnackbar } = useSnackbar();

    const { t } = useTranslation();

    return (
      <FilePond
        ref={ref}
        acceptedFileTypes={["application/pdf"]}
        itemInsertLocation="after"
        allowMultiple={true}
        name="files"
        labelIdle={t("uploadFileLabelIdle")}
        required
        beforeAddFile={(file) => {
          if (fileNamesToIgnore) {
            if (typeof file !== "string") {
              const { filename } = file;

              if (fileNamesToIgnore.includes(filename)) {
                enqueueSnackbar(
                  t("fileAlreadyAdded", {
                    fileName: filename,
                  }),
                  {
                    variant: "warning",
                  }
                );

                return false;
              }
            }
          }

          return true;
        }}
        {...props}
      />
    );
  }
);

export default DocumentUploader;
