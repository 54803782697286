import { Box, BoxProps } from "@mui/material";
import React from "react";

interface TabPanelProps extends BoxProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  ...other
}) => (
  <Box
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
    sx={{
      height: "100%",
    }}
    {...other}
  >
    {children}
  </Box>
);

export default TabPanel;
