import { forwardRef } from "react";
import { Helmet } from "react-helmet-async";
// material
import { Box, BoxProps } from "@mui/material";
import { enUS as enLocale, it as itLocale } from "date-fns/locale";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { useTranslation } from "react-i18next";
import PageTitle from "./PageTitle";
// ----------------------------------------------------------------------

interface IPageProps extends BoxProps {
  showBackButton?: boolean;
  hidePageTitle?: boolean;
}

const localeMap = {
  en: enLocale,
  it: itLocale,
};

const Page = forwardRef<HTMLDivElement, IPageProps>(
  (
    {
      children,
      title = "",
      showBackButton = false,
      hidePageTitle = true,
      ...other
    },
    ref
  ) => {
    const { i18n } = useTranslation();
    const { REACT_APP_NAME } = process.env;

    return (
      <Box
        ref={ref}
        sx={{
          height: "100%",
        }}
        {...other}
      >
        <Helmet>
          <title>
            {title} | {REACT_APP_NAME}
          </title>
        </Helmet>

        <PageTitle
          showBackButton={showBackButton}
          hidePageTitle={hidePageTitle}
        >
          {title}
        </PageTitle>

        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={
            // @ts-ignore
            localeMap[i18n.language]
          }
        >
          {children}
        </LocalizationProvider>
      </Box>
    );
  }
);

export default Page;
