import React from "react";
import { Link as RouterLink, Outlet } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
// components
import Logo from "../components/Logo";

// ----------------------------------------------------------------------

const StyledHeader = styled("header")(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: "100%",
  position: "absolute",
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

const StyledRouterLink = styled(RouterLink)(({ theme }) => ({
  width: 215,
  maxWidth: "85vw",
  display: "block",
  [theme.breakpoints.down("sm")]: {
    margin: "0 auto",
  },
  [theme.breakpoints.up("sm")]: {
    marginLeft: "auto",
  },
}));

// ----------------------------------------------------------------------

const LogoOnlyLayout: React.FC = () => (
  <>
    <StyledHeader>
      <StyledRouterLink to="/">
        <Logo />
      </StyledRouterLink>
    </StyledHeader>
    <Outlet />
  </>
);

export default LogoOnlyLayout;
