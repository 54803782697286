// ----------------------------------------------------------------------
import type { Theme } from "@mui/material";

const DataGrid = (theme: Theme) => ({
  MuiDataGrid: {
    styleOverrides: {
      root: {
        boxShadow: theme.customShadows.z8,
        borderRadius: 16,
      },
    },
  },
});

export default DataGrid;
