import { FilePondFile } from "filepond";
import Parse from "parse";
import React from "react";
import { ICustomer } from "../types/Customer";
import { IEvent } from "../types/Event";

const ContractContext = React.createContext<
  | {
      contractNumber?: string | null;
      setContractNumber: React.Dispatch<
        React.SetStateAction<string | undefined | null>
      >;
      customer?: ICustomer;
      setCustomer: React.Dispatch<React.SetStateAction<ICustomer | undefined>>;
      files: FilePondFile[];
      setFiles: React.Dispatch<React.SetStateAction<FilePondFile[]>>;
      savedFiles?: Parse.File[];
      setSavedFiles: React.Dispatch<React.SetStateAction<Parse.File[]>>;
      event?: IEvent;
      setEvent: React.Dispatch<React.SetStateAction<IEvent | undefined>>;
    }
  | undefined
>(undefined);

const ContractProvider: React.FC = ({ children }) => {
  const [customer, setCustomer] = React.useState<ICustomer>();
  const [event, setEvent] = React.useState<IEvent>();
  const [contractNumber, setContractNumber] = React.useState<string | null>();
  const [files, setFiles] = React.useState<FilePondFile[]>([]);
  const [savedFiles, setSavedFiles] = React.useState<Parse.File[]>([]);

  return (
    <ContractContext.Provider
      value={{
        customer,
        setCustomer,
        contractNumber,
        setContractNumber,
        files,
        setFiles,
        savedFiles,
        setSavedFiles,
        event,
        setEvent,
      }}
    >
      {children}
    </ContractContext.Provider>
  );
};

const useContract = () => {
  const context = React.useContext(ContractContext);

  if (context === undefined) {
    throw new Error("useContract must be used within a ContractProvider");
  }

  return context;
};

export { ContractProvider, useContract };
