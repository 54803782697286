import AddIcon from "@mui/icons-material/Add";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SendToMobileIcon from "@mui/icons-material/SendToMobile";
import ShareIcon from "@mui/icons-material/Share";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Parse from "parse";
import QRCode from "qrcode.react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useCopyToClipboard } from "react-use";
import { RWebShare } from "react-web-share";
import { ICustomer } from "../types/Customer";

interface IShareContractProps {
  isCreatingContract: boolean;
  contract?: Parse.Object<Parse.Attributes>;
  customer?: ICustomer;
  toggleSmsModalVisibility: () => void;
  resetStepper: () => void;
  showButtons?: boolean;
}

const ShareContract: React.FC<IShareContractProps> = ({
  isCreatingContract,
  contract,
  customer,
  toggleSmsModalVisibility,
  resetStepper,
  showButtons = true,
}) => {
  const { t } = useTranslation();

  const shareLink = React.useMemo(() => {
    if (contract) {
      return `${window.location.origin}/sign?oid=${contract.id}`;
    }

    return null;
  }, [contract]);

  const [, copyLinkToClipboard] = useCopyToClipboard();

  return (
    <Stack spacing={4}>
      <Stack spacing={1} alignItems="center">
        <Avatar
          sx={{
            backgroundColor: "primary.main",
          }}
        >
          <AssignmentTurnedInIcon />
        </Avatar>

        <Typography variant="subtitle1">{t("contractCreated")}</Typography>
      </Stack>

      {isCreatingContract && (
        <Box
          sx={{
            display: "flex",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {contract && shareLink && (
        <Stack spacing={4}>
          <Typography variant="body1">
            {t("sendViaQrInstruction.description")}
          </Typography>

          <Stack spacing={2} alignItems="center">
            <QRCode value={shareLink} width={1024} height={1024} level="H" />

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              <Typography variant="body1" fontStyle="italic">
                {shareLink}
              </Typography>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Tooltip
                  title={t("copyToClipboard") as string}
                  enterDelay={750}
                >
                  <IconButton
                    color="primary"
                    aria-label={t("copyToClipboard")}
                    onClick={() => copyLinkToClipboard(shareLink)}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>

                <RWebShare
                  data={{
                    text: t("smsText", {
                      fullName: customer?.fullName,
                      agencyName: Parse.User.current()?.get("agency"),
                      url: "",
                    }) as string,
                    url: shareLink,
                    title: t("newContractToSign"),
                  }}
                >
                  <Tooltip title={t("shareLink") as string} enterDelay={750}>
                    <IconButton color="primary" aria-label={t("shareLink")}>
                      <ShareIcon />
                    </IconButton>
                  </Tooltip>
                </RWebShare>

                <Tooltip
                  title={t("sendViaSmsInstruction.title") as string}
                  enterDelay={750}
                >
                  <IconButton
                    color="primary"
                    aria-label={t("sendViaSmsInstruction.title")}
                    onClick={toggleSmsModalVisibility}
                  >
                    <SendToMobileIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          </Stack>

          {showButtons && (
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="space-around"
            >
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={resetStepper}
              >
                {t("createNewContract")}
              </Button>

              <LoadingButton
                loading={contract === undefined}
                variant="outlined"
                startIcon={<VisibilityIcon />}
                component={Link}
                to={`/contracts/${contract?.id}`}
              >
                {t("viewNewlyCreatedContract")}
              </LoadingButton>
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default ShareContract;
