import React from "react";
import { useNavigate } from "react-router-dom";
// material
import { Stack, Typography, IconButton, Tooltip } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import usePortal from "react-useportal";

interface IPageTitleProps {
  showBackButton: boolean;
  hidePageTitle: boolean;
}

const Title: React.FC<IPageTitleProps> = ({
  children,
  showBackButton,
  hidePageTitle,
}) => {
  const { Portal } = usePortal({
    // @ts-ignore
    bindTo: document && document.getElementById("navbar-page-title"),
  });
  const navigate = useNavigate();

  return (
    <Portal>
      {showBackButton && (
        <Stack flexDirection="row" alignItems="center">
          <Tooltip enterDelay={1000} title="Torna indietro">
            <IconButton onClick={() => navigate(-1)} sx={{ color: "gray.700" }}>
              <KeyboardBackspaceIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      )}

      {!hidePageTitle && (
        <Typography
          variant="h4"
          color="primary"
          sx={{
            position: "absolute",
            left: "50%",
            top: "25%",
            transform: "translateX(-50%)",
          }}
        >
          {children}
        </Typography>
      )}
    </Portal>
  );
};

export default Title;
