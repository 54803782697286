import React from "react";
// material
import { Container, Grid, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
// layouts
// import AuthLayout from '../../layouts/AuthLayout';
// components
import { MHidden } from "../../components/@material_extend";
import { LoginForm } from "../../components/forms";
import Page from "../../components/Page";
import useAppName from "../../hooks/useAppName";
// import AuthSocial from '../components/authentication/AuthSocial';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const Login: React.FC = () => {
  const appName = useAppName();

  return (
    <RootStyle title="Login" hidePageTitle>
      {/* <AuthLayout>
        Non hai un'account? &nbsp;
        <Link
          underline="none"
          variant="subtitle2"
          component={RouterLink}
          to="/auth/register"
        >
          Inizia da qui
        </Link>
      </AuthLayout> */}

      <Grid
        container
        component="main"
        sx={{ height: "100vh" }}
        justifyContent="space-between"
      >
        <MHidden width="mdDown">
          <Grid
            item
            xs={false}
            sm={4}
            md={5}
            sx={{
              backgroundImage: `url(${process.env.REACT_APP_LOGIN_IMAGE_URL})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
          />
        </MHidden>

        <Grid item xs={12} md={6} component={Container}>
          <ContentStyle>
            <Stack sx={{ mb: 5 }}>
              <Typography variant="h4" gutterBottom>
                Esegui l'accesso a {appName}
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                Inserisci i dettagli richiesti
              </Typography>
            </Stack>
            {/* <AuthSocial /> */}

            <LoginForm />
          </ContentStyle>
        </Grid>
      </Grid>
    </RootStyle>
  );
};

export default Login;
