import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { DatePicker, LoadingButton } from "@mui/lab";
import {
  Container,
  Drawer,
  Fade,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import * as dateFns from "date-fns";
import MuiPhoneNumber from "material-ui-phone-number";
import Parse from "parse";
import React from "react";

import type { DrawerProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import useAppName from "../../hooks/useAppName";
import type { TContract } from "../../types/Contract";

interface IDocumentListFilterProps {
  open: DrawerProps["open"];
  onClose: NonNullable<DrawerProps["onClose"]>;
  setContracts: React.Dispatch<React.SetStateAction<Parse.Object<TContract>[]>>;
  isLoadingContracts: boolean;
  setIsLoadingContracts: React.Dispatch<React.SetStateAction<boolean>>;
  setFilters: React.Dispatch<React.SetStateAction<string | null>>;
}

enum SignatureStatus {
  Signed,
  NotSigned,
  PartiallySigned,
}

const DocumentListFilter: React.FC<IDocumentListFilterProps> = ({
  open,
  onClose,
  setContracts,
  isLoadingContracts,
  setIsLoadingContracts,
  setFilters,
}) => {
  const { t } = useTranslation();
  const appName = useAppName();

  const [signatureStatus, setSignatureStatus] = React.useState<
    SignatureStatus | ""
  >("");

  const [contractNumber, setContractNumber] = React.useState<string>("");
  const [fullName, setFullname] = React.useState<string>("");
  const [mobileNumber, setMobileNumber] = React.useState<string>("");
  const [creationDate, setCreationDate] = React.useState<Date | null>(null);
  const [eventPlace, setEventPlace] = React.useState<string>("");
  const [eventDate, setEventDate] = React.useState<Date | null>(null);
  const [departureDate, setDepartureDate] = React.useState<Date | null>(null);
  const [eventName, setEventName] = React.useState<string>("");
  const [fiscalCode, setFiscalCode] = React.useState<string>("");

  const query = new Parse.Query<Parse.Object<TContract>>(
    process.env.REACT_APP_CONTRACT_TABLE_NAME!
  ).equalTo("user", Parse.User.current()!);

  if (appName === "Sign&Travel") {
    // @ts-ignore
    query.addAscending("customer.signDate");
  } else {
    query.addDescending("createdAt");
  }

  const handleResetFilters: React.MouseEventHandler = async () => {
    setFilters(null);
    setContractNumber("");
    setFullname("");
    setMobileNumber("");
    setCreationDate(null);
    setSignatureStatus("");
    setEventName("");
    setEventPlace("");
    setEventDate(null);
    setFiscalCode("");
    setDepartureDate(null);

    try {
      setIsLoadingContracts(true);

      setContracts(await query.find());

      // @ts-ignore
      onClose();
    } finally {
      setIsLoadingContracts(false);
    }
  };

  const handleSearch: React.MouseEventHandler = async () => {
    try {
      setIsLoadingContracts(true);

      switch (signatureStatus) {
        case SignatureStatus.Signed:
          query.exists("signed");
          break;

        case SignatureStatus.NotSigned:
          query.doesNotExist("signed");
          break;

        default:
          break;
      }

      if (contractNumber && contractNumber.length > 0) {
        query.contains("contractNumber", contractNumber.trim());
      }

      if (fullName && fullName.length > 0) {
        query.matches(
          // @ts-ignore
          "customer.fullName",
          fullName.trim(),
          "i"
        );
      }

      if (fiscalCode && fiscalCode.length > 0) {
        query.matches(
          // @ts-ignore
          "customer.fiscalCode",
          fiscalCode.trim(),
          "i"
        );
      }

      if (mobileNumber) {
        query.contains("phoneNumber", mobileNumber.trim());
      }

      if (creationDate) {
        query.greaterThanOrEqualTo(
          "createdAt",
          dateFns.startOfDay(creationDate)
        );
        query.lessThan("createdAt", dateFns.endOfDay(creationDate));
      }

      if (eventName && eventName.length > 0) {
        query.matches(
          // @ts-ignore
          "event.name",
          eventName.trim(),
          "i"
        );
      }

      if (eventPlace && eventPlace.length > 0) {
        query.matches(
          // @ts-ignore
          "event.place",
          eventPlace.trim(),
          "i"
        );
      }

      if (eventDate) {
        query.greaterThanOrEqualTo(
          // @ts-ignore
          "event.date",
          dateFns.startOfDay(eventDate)
        );
        query.lessThan(
          // @ts-ignore
          "event.date",
          dateFns.endOfDay(eventDate)
        );
      }

      if (departureDate) {
        query.greaterThanOrEqualTo(
          // @ts-ignore
          "customer.departureDate",
          dateFns.startOfDay(departureDate)
        );
        query.lessThan(
          // @ts-ignore
          "customer.departureDate",
          dateFns.endOfDay(departureDate)
        );
      }

      setFilters(query.toJSON());

      setContracts(await query.find());

      // @ts-ignore
      onClose();
    } finally {
      setIsLoadingContracts(false);
    }
  };

  return (
    <Drawer anchor="bottom" open={open} onClose={onClose}>
      <Container
        sx={{
          my: 3,
          position: "relative",
        }}
      >
        <Tooltip title={t("closeFiltersSection") as string} enterDelay={750}>
          <IconButton
            aria-label="chiudi sezione dei filtri"
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
            // @ts-ignore
            onClick={() => onClose()}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>

        <Stack spacing={1} direction="row" alignItems="center">
          <FilterListIcon sx={{ mr: 1 }} color="primary" />

          <Typography fontWeight="800" fontSize="x-large" color="primary">
            {t("filterContracts")}
          </Typography>
        </Stack>

        <Stack
          spacing={5}
          sx={{
            py: 4,
          }}
        >
          <Stack spacing={5} direction={{ xs: "column", sm: "row" }}>
            {appName !== "Sign&Gass" && (
              <TextField
                fullWidth
                label={t("contractNumber")}
                variant="standard"
                disabled={isLoadingContracts}
                value={contractNumber}
                onChange={({ currentTarget: { value } }) => {
                  setContractNumber(value);
                }}
                InputProps={{
                  endAdornment: (
                    <Fade
                      in={Boolean(contractNumber && contractNumber?.length > 0)}
                    >
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          edge="end"
                          onClick={() => {
                            setContractNumber("");
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    </Fade>
                  ),
                }}
              />
            )}

            <DatePicker
              label={t("creationDate")}
              disabled={isLoadingContracts}
              value={creationDate}
              onChange={(newDate) => {
                if (newDate) {
                  newDate.setHours(0, 0, 0, 0);
                  setCreationDate(newDate);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} fullWidth variant="standard" />
              )}
            />

            {appName === "Sign&Travel" && (
              <DatePicker
                label={t("departureDate")}
                disabled={isLoadingContracts}
                value={departureDate}
                onChange={(newDate) => {
                  if (newDate) {
                    newDate.setHours(0, 0, 0, 0);
                    setDepartureDate(newDate);
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth variant="standard" />
                )}
              />
            )}

            {appName === "Sign&Gass" && (
              <DatePicker
                label={t("eventDate")}
                disabled={isLoadingContracts}
                value={eventDate}
                onChange={(newDate) => {
                  if (newDate) {
                    newDate.setHours(0, 0, 0, 0);

                    setEventDate(newDate);
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} fullWidth variant="standard" />
                )}
              />
            )}

            <FormControl fullWidth>
              <InputLabel id="signature-status-select-label">
                {t("contractSignatureStatus.label")}
              </InputLabel>
              <Select
                labelId="signature-status-select-label"
                id="signature-status-select"
                disabled={isLoadingContracts}
                value={signatureStatus}
                label="Stato delle firme"
                onChange={({ target: { value } }) => {
                  setSignatureStatus(value as SignatureStatus);
                }}
                variant="standard"
              >
                <MenuItem value="" disabled>
                  Seleziona stato delle firme
                </MenuItem>

                <MenuItem value={SignatureStatus.Signed}>
                  {t("documentSigned")}
                </MenuItem>

                <MenuItem value={SignatureStatus.NotSigned}>
                  {t("documentNotSigned")}
                </MenuItem>
              </Select>
            </FormControl>
          </Stack>

          {appName === "Sign&Gass" && (
            <Stack spacing={5} direction={{ xs: "column", sm: "row" }}>
              <TextField
                fullWidth
                label={t("eventName")}
                disabled={isLoadingContracts}
                variant="standard"
                value={eventName}
                onChange={({ currentTarget: { value } }) => {
                  setEventName(value);
                }}
                InputProps={{
                  endAdornment: (
                    <Fade in={Boolean(eventName && eventName?.length > 0)}>
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          edge="end"
                          onClick={() => {
                            setEventName("");
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    </Fade>
                  ),
                }}
              />

              <TextField
                fullWidth
                label={t("eventPlace")}
                disabled={isLoadingContracts}
                variant="standard"
                value={eventPlace}
                onChange={({ currentTarget: { value } }) => {
                  setEventPlace(value);
                }}
                InputProps={{
                  endAdornment: (
                    <Fade in={Boolean(eventPlace && eventPlace?.length > 0)}>
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          edge="end"
                          onClick={() => {
                            setEventPlace("");
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    </Fade>
                  ),
                }}
              />
            </Stack>
          )}

          <Stack
            spacing={5}
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
          >
            <TextField
              fullWidth
              label={t("firstName")}
              variant="standard"
              value={fullName}
              disabled={isLoadingContracts}
              onChange={({ currentTarget: { value } }) => {
                setFullname(value);
              }}
              InputProps={{
                endAdornment: (
                  <Fade in={Boolean(fullName && fullName?.length > 0)}>
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        edge="end"
                        onClick={() => {
                          setFullname("");
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  </Fade>
                ),
              }}
            />

            <TextField
              fullWidth
              label={t("fiscalCode")}
              variant="standard"
              value={fiscalCode}
              disabled={isLoadingContracts}
              onChange={({ currentTarget: { value } }) => {
                setFiscalCode(value);
              }}
              InputProps={{
                endAdornment: (
                  <Fade in={Boolean(fiscalCode && fiscalCode?.length > 0)}>
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        edge="end"
                        onClick={() => {
                          setFiscalCode("");
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  </Fade>
                ),
              }}
            />

            <MuiPhoneNumber
              defaultCountry={"it"}
              disableAreaCodes
              disabled={isLoadingContracts}
              fullWidth
              value={mobileNumber}
              onChange={(newMobileNumber) => {
                setMobileNumber(newMobileNumber as string);
              }}
              sx={{
                svg: {
                  maxHeight: "20px",
                  height: "100%",
                },
              }}
              style={{
                marginTop: "15px",
              }}
              InputProps={{
                endAdornment: (
                  <Fade in={Boolean(mobileNumber && mobileNumber?.length > 0)}>
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        edge="end"
                        onClick={() => {
                          setMobileNumber("");
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  </Fade>
                ),
              }}
            />
          </Stack>
        </Stack>

        <Stack
          spacing={{
            xs: 10,
            sm: 3,
            md: 3,
          }}
          alignItems="center"
          justifyContent="space-between"
          direction="row"
          sx={(theme) => ({
            maxWidth: "fit-content",
            marginLeft: "auto",
            [theme.breakpoints.down("sm")]: {
              position: "fixed",
              bottom: 0,
              paddingBottom: "10px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "100%",
              backdropFilter: "blur(6px)",
              WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
              backgroundColor: alpha(theme.palette.background.default, 0.72),
              zIndex: 1,
            },
          })}
        >
          <LoadingButton
            startIcon={<RotateLeftIcon />}
            onClick={handleResetFilters}
            loading={isLoadingContracts}
          >
            {t("resetFilters")}
          </LoadingButton>

          <LoadingButton
            loading={isLoadingContracts}
            onClick={handleSearch}
            variant="contained"
            startIcon={<ManageSearchIcon />}
          >
            {t("showResults")}
          </LoadingButton>
        </Stack>
      </Container>
    </Drawer>
  );
};

export default DocumentListFilter;
