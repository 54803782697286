// ----------------------------------------------------------------------
import type { Theme } from "@mui/material";

const Input = (theme: Theme) => ({
  MuiInputBase: {
    styleOverrides: {
      root: {
        "&.Mui-disabled": {
          "& svg": { color: theme.palette.text.disabled },
        },
      },
      input: {
        "&::placeholder": {
          opacity: 1,
          color: theme.palette.text.disabled,
        },
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      underline: {
        "&:before": {
          // @ts-ignore
          borderBottomColor: theme.palette.grey[500_56],
        },
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        // @ts-ignore
        backgroundColor: theme.palette.grey[500_12],
        "&:hover": {
          // @ts-ignore
          backgroundColor: theme.palette.grey[500_16],
        },
        "&.Mui-focused": {
          backgroundColor: theme.palette.action.focus,
        },
        "&.Mui-disabled": {
          backgroundColor: theme.palette.action.disabledBackground,
        },
      },
      underline: {
        "&:before": {
          // @ts-ignore
          borderBottomColor: theme.palette.grey[500_56],
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        "& .MuiOutlinedInput-notchedOutline": {
          // @ts-ignore
          borderColor: theme.palette.grey[500_32],
        },
        "&.Mui-disabled": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.action.disabledBackground,
          },
        },
      },
    },
  },
});

export default Input;
