import {
  Box,
  Container,
  Grid,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useContract } from "../ContractContext";
import Page from "../Page";
import CustomerInformation from "./Steps/CustomerInformation";
import SendDocuments from "./Steps/SendDocuments";
import UploadDocuments from "./Steps/UploadDocuments";

enum Steps {
  GENERAL_INFO,
  UPLOAD_FILES,
  SEND_CONTRACT,
}

const DocumentUploadStepperContext = React.createContext<
  | {
      handleNext: () => void;
      resetStepper: () => void;
    }
  | undefined
>(undefined);

export const useDocumentUploadStepper = () => {
  const context = React.useContext(DocumentUploadStepperContext);

  if (context === undefined) {
    throw new Error(
      "useDocumentUploadStepper must be used within a DocumentUploadStepperProvider"
    );
  }

  return context;
};

const DocumentsUploadStepper: React.FC = () => {
  const { setFiles, setSavedFiles } = useContract();
  const { t } = useTranslation();

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());

  const steps = [
    t("customerInformation"),
    t("uploadFiles"),
    t("sendDocuments"),
  ];

  const isStepSkipped = (step: number) => skipped.has(step);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const resetStepper = () => {
    setSavedFiles([]);
    setFiles([]);
    setActiveStep(Steps.GENERAL_INFO);
  };

  const getStepImage = (step: number) => {
    const {
      REACT_APP_UPLOAD_IMAGE_URL,
      REACT_APP_SEND_MESSAGE_IMAGE_URL,
      REACT_APP_ADDITIONAL_INFO_URL,
    } = process.env;

    switch (step) {
      case Steps.GENERAL_INFO:
        return REACT_APP_ADDITIONAL_INFO_URL;

      case Steps.UPLOAD_FILES:
        return REACT_APP_UPLOAD_IMAGE_URL;

      case Steps.SEND_CONTRACT:
        return REACT_APP_SEND_MESSAGE_IMAGE_URL;

      default:
        return REACT_APP_ADDITIONAL_INFO_URL;
    }
  };

  return (
    <Page title="Carica nuovi documenti da firmare">
      <Grid
        container
        sx={{
          height: "100%",
          overflowY: "hidden",
        }}
        justifyContent="space-between"
      >
        <Grid
          item
          xs={12}
          md={6}
          component={Container}
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: {
              marginTop: `calc(${theme.appBar.md}px + 30px)`,
            },
            [theme.breakpoints.up("md")]: {
              marginTop: `calc(${theme.appBar.lg}px + 30px)`,
            },
          })}
        >
          <Box sx={{ width: "100%", height: "100%" }}>
            <Stack spacing={2} direction="row" alignItems="center">
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                sx={{
                  flexBasis: "100%",
                }}
              >
                {steps.map((label, index) => {
                  const stepProps: { completed?: boolean } = {};

                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }

                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel>
                        <Typography variant="subtitle2">{label}</Typography>
                      </StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Stack>

            <DocumentUploadStepperContext.Provider
              value={{
                handleNext,
                resetStepper,
              }}
            >
              {activeStep === Steps.GENERAL_INFO && <CustomerInformation />}

              {activeStep === Steps.UPLOAD_FILES && <UploadDocuments />}

              {activeStep === Steps.SEND_CONTRACT && <SendDocuments />}
            </DocumentUploadStepperContext.Provider>
          </Box>
        </Grid>

        <Grid
          item
          xs={false}
          sm={4}
          md={6}
          sx={{
            backgroundImage: `url(${getStepImage(activeStep)})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[50]
                : theme.palette.grey[900],
            backgroundSize: "contain",
            backgroundPosition: "center",
            transition: "background-image 0.2s ease-in-out",
          }}
        />
      </Grid>
    </Page>
  );
};

export default DocumentsUploadStepper;
