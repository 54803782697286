import CalendarIcon from "@mui/icons-material/CalendarTodayOutlined";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import EventIcon from "@mui/icons-material/Event";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import ShareIcon from "@mui/icons-material/Share";
import SportsScoreIcon from "@mui/icons-material/SportsScore";

import {
  Avatar,
  Backdrop,
  Box,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Stack,
  styled,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { DataGrid, GridColDef, itIT } from "@mui/x-data-grid";
import Parse from "parse";
import React from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAsync, useToggle } from "react-use";

import type { File as ParseFile } from "parse";
import { ContractProvider } from "../../components/ContractContext";
import DocumentsUploadDialog from "../../components/DocumentsUploadDialog";
import Page from "../../components/Page";
import PageLoader from "../../components/PageLoader";
import ShareContractDialog from "../../components/ShareContractDialog";
import useAppName from "../../hooks/useAppName";

import SendSmsDialog from "../../components/SendSmsDialog";

const StyledCard = styled(Card)(() => ({
  minHeight: "195px",
}));

const ContractDetails: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const appName = useAppName();

  const [files, setFiles] = React.useState<
    {
      id: string;
      name: string;
      url: string;
    }[]
  >([]);

  const [isSmsModalVisible, toggleSmsModalVisibility] = useToggle(false);
  const [isSpeedDialogVisible, toggleSpeedDialogVisibility] =
    React.useState<boolean>(false);

  const [contract, setContract] = React.useState<
    Parse.Object<Parse.Attributes> | undefined
  >();

  const [isUploadDialaogVisible, toggleUploadDiaologVisibility] =
    React.useState<boolean>(false);

  const [isShareContractDialogVisible, toggleShareContractDiaologVisibility] =
    React.useState<boolean>(false);

  const isFileSigned = (filename: string): boolean => {
    const signedDocuments: ParseFile[] = contract?.get("signed");
    const parsedFilename = filename.split("_").slice(1).join("_");

    if (signedDocuments && Array.isArray(signedDocuments)) {
      return signedDocuments.some(
        (file) => file.name().split("_").slice(1).join("_") === parsedFilename
      );
    }

    return false;
  };

  const getSignedFileUrl = (filename: string): string => {
    const signedDocuments: ParseFile[] = contract?.get("signed");
    const parsedFilename = filename.split("_").slice(1).join("_");

    return (
      signedDocuments
        .find(
          (signedDocument) =>
            signedDocument.name().split("_").slice(1).join("_") ===
            parsedFilename
        )
        ?.url() || ""
    );
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", hide: true },
    {
      field: "actions",
      headerName: "",
      width: 25,
      renderCell: ({ row }) => (
        <Tooltip enterDelay={750} title={"Download file"}>
          <IconButton
            color="primary"
            aria-label="download file"
            component="a"
            href={isFileSigned(row.name) ? getSignedFileUrl(row.name) : row.url}
            target="_blank"
          >
            <DownloadIcon />
          </IconButton>
        </Tooltip>
      ),
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "name",
      headerName: "Nome",
      type: "string",
      flex: 1,
      renderCell: ({ row }) => (
        <Chip
          label={row.name.split("_").slice(1).join("_")}
          color="primary"
          variant="outlined"
        />
      ),
    },
    {
      field: "",
      headerName: "Stato firma",
      width: 200,
      renderCell: ({ row }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [isSigned, setIsSigned] = React.useState<boolean>();

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useAsync(async () => {
          setIsSigned(isFileSigned(row.name));
        }, [row.name]);

        if (isSigned === undefined) {
          return <CircularProgress />;
        }

        return isSigned ? (
          <Chip label={t("documentSigned")} color="success" />
        ) : (
          <Chip label={t("documentNotSigned")} color="warning" />
        );
      },
    },
  ];

  const getContractStatus = (numberOfDocumentsSigned: number): string => {
    if (numberOfDocumentsSigned) {
      if (numberOfDocumentsSigned === contract?.get("toSign")?.length) {
        return t("allDocumentsSigned");
      }
      return t("someDocumentsSigned");
    }
    return t("noDocumentSigned");
  };

  const getContract = async () => {
    setContract(
      await new Parse.Query(process.env.REACT_APP_CONTRACT_TABLE_NAME!)
        .equalTo("objectId", id)
        .first()
    );
  };

  const { loading: isLoadingContracts } = useAsync(getContract, []);

  const getContractLabel = () => {
    switch (appName) {
      case "Sign&Gass":
        return t("eventDate");

      case "Sign&Travel":
        return t("departureDate");

      default:
        return t("contractCreationDate.label");
    }
  };

  const getContractDate = () => {
    switch (appName) {
      case "Sign&Gass":
        return contract?.get("event").date;

      case "Sign&Travel":
        return contract?.get("customer").departureDate;

      default:
        return contract?.get("createdAt");
    }
  };

  const openUploadDialog = () => {
    toggleUploadDiaologVisibility(true);
  };

  const openShareContractDialog = () => {
    toggleShareContractDiaologVisibility(true);
  };

  React.useEffect(() => {
    if (contract) {
      setFiles(
        (contract.get("toSign") as ParseFile[]).map((file, index) => ({
          id: `${file.name()}-${index}`,
          name: file.name(),
          url: file.url(),
        }))
      );
    }
  }, [contract]);

  if (isLoadingContracts) {
    return <PageLoader />;
  }

  return (
    <ContractProvider>
      <Page title="Dettagli contratto" showBackButton mb={20}>
        <Backdrop
          open={isSpeedDialogVisible}
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        />

        <Container
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: {
              marginTop: `calc(${theme.appBar.md}px + 48px)`,
            },
            [theme.breakpoints.up("md")]: {
              marginTop: `calc(${theme.appBar.lg}px + 48px)`,
            },
          })}
        >
          <Stack spacing={3}>
            <Box sx={{ position: "relative" }}>
              <Grid
                container
                spacing={{ xs: 2, md: 4 }}
                columns={{ xs: 1, sm: 4, md: 12 }}
                alignItems="stretch"
              >
                <Grid item xs={1} sm={2} md={3} lg={3}>
                  <Zoom in>
                    <StyledCard elevation={6}>
                      <CardContent>
                        <Stack spacing={2} alignItems="center">
                          <Stack spacing={2} alignItems="center">
                            <Avatar
                              sx={{
                                backgroundColor: "primary.main",
                              }}
                            >
                              <EventIcon />
                            </Avatar>

                            <Typography variant="subtitle1">
                              {getContractLabel()}
                            </Typography>
                          </Stack>

                          <Chip
                            label={new Intl.DateTimeFormat("it-IT").format(
                              getContractDate()
                            )}
                            variant="outlined"
                          />
                        </Stack>
                      </CardContent>
                    </StyledCard>
                  </Zoom>
                </Grid>

                {appName === "Sign&Travel" && (
                  <Grid item xs={1} sm={2} md={3} lg={3}>
                    <Zoom
                      in
                      timeout={{
                        enter: 1000,
                      }}
                    >
                      <StyledCard elevation={6}>
                        <CardContent>
                          <Stack spacing={2} alignItems="center">
                            <Stack spacing={2} alignItems="center">
                              <Avatar
                                sx={{
                                  backgroundColor: "primary.main",
                                }}
                              >
                                <CalendarIcon />
                              </Avatar>

                              <Typography variant="subtitle1" align="center">
                                {t("signDate")}
                              </Typography>
                            </Stack>

                            <Chip
                              label={
                                contract?.get("customer")?.signDate ? 
                                new Intl.DateTimeFormat("it-IT").format(
                                  contract?.get("customer")?.signDate
                                ) : "N/D"
                              }
                              variant="outlined"
                            />
                          </Stack>
                        </CardContent>
                      </StyledCard>
                    </Zoom>
                  </Grid>
                )}

                {appName === "Sign&Gass" && (
                  <Grid item xs={1} sm={2} md={3} lg={3}>
                    <Zoom
                      in
                      timeout={{
                        enter: 1000,
                      }}
                    >
                      <StyledCard elevation={6}>
                        <CardContent>
                          <Stack spacing={2} alignItems="center">
                            <Stack spacing={2} alignItems="center">
                              <Avatar
                                sx={{
                                  backgroundColor: "primary.main",
                                }}
                              >
                                <SportsScoreIcon />
                              </Avatar>

                              <Typography variant="subtitle1" align="center">
                                {t("eventName")}
                              </Typography>
                            </Stack>

                            <Chip
                              label={contract?.get("event")?.name || "N/D"}
                              variant="outlined"
                            />
                          </Stack>
                        </CardContent>
                      </StyledCard>
                    </Zoom>
                  </Grid>
                )}

                {appName !== "Sign&Gass" && (
                  <Grid item xs={1} sm={2} md={3} lg={3}>
                    <Zoom
                      in
                      timeout={{
                        enter: 1000,
                      }}
                    >
                      <StyledCard elevation={6}>
                        <CardContent>
                          <Stack spacing={2} alignItems="center">
                            <Stack spacing={2} alignItems="center">
                              <Avatar
                                sx={{
                                  backgroundColor: "primary.main",
                                }}
                              >
                                <PhoneIphoneIcon />
                              </Avatar>

                              <Typography variant="subtitle1" align="center">
                                {t("contractPhoneNumber.label")}
                              </Typography>
                            </Stack>

                            <Chip
                              label={contract?.get("phoneNumber") || "N/D"}
                              variant="outlined"
                            />
                          </Stack>
                        </CardContent>
                      </StyledCard>
                    </Zoom>
                  </Grid>
                )}

                {appName !== "Sign&Travel" && (
                  <Grid item xs={1} sm={2} md={3} lg={3}>
                    <Zoom
                      in
                      timeout={{
                        enter: 1500,
                      }}
                    >
                      <StyledCard elevation={6}>
                        <CardContent>
                          <Stack spacing={2} alignItems="center">
                            <Stack spacing={2} alignItems="center">
                              <Avatar
                                sx={{
                                  backgroundColor: "#ED9E31",
                                }}
                              >
                                <InsertDriveFileIcon />
                              </Avatar>

                              <Typography variant="subtitle1">
                                {t("numberOfDocuments.label")}
                              </Typography>
                            </Stack>

                            <Chip
                              label={
                                <CountUp
                                  end={contract?.get("toSign").length}
                                  delay={1}
                                  duration={1}
                                />
                              }
                              variant="outlined"
                            />
                          </Stack>
                        </CardContent>
                      </StyledCard>
                    </Zoom>
                  </Grid>
                )}

                <Grid item xs={1} sm={2} md={3} lg={3}>
                  <Zoom
                    in
                    timeout={{
                      enter: 2000,
                    }}
                  >
                    <StyledCard elevation={6}>
                      <CardContent>
                        <Stack spacing={2} alignItems="center">
                          <Stack spacing={2} alignItems="center">
                            <Avatar
                              sx={{
                                backgroundColor: "#B23D82",
                              }}
                            >
                              <DriveFileRenameOutlineIcon />
                            </Avatar>

                            <Typography variant="subtitle1">
                              {t("contractSignatureStatus.label")}
                            </Typography>
                          </Stack>

                          <Chip
                            label={getContractStatus(
                              contract?.get("signed")?.length
                            )}
                            variant="outlined"
                          />
                        </Stack>
                      </CardContent>
                    </StyledCard>
                  </Zoom>
                </Grid>
              </Grid>
            </Box>

            <DataGrid
              loading={isLoadingContracts}
              rows={files}
              columns={columns}
              pagination
              rowCount={files.length}
              density="comfortable"
              editMode="row"
              autoHeight
              pageSize={8}
              rowsPerPageOptions={[8]}
              localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            />
          </Stack>
        </Container>

        {contract && (
          <>
            <Zoom
              in
              timeout={{
                enter: 1000,
              }}
            >
              <SpeedDial
                ariaLabel={t("options")}
                sx={{
                  position: "fixed",
                  bottom: 22,
                  right: 20,
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                icon={
                  <>
                    <SpeedDialIcon
                      icon={<MoreVertIcon />}
                      openIcon={<CloseIcon />}
                      sx={{
                        mr: 1,
                      }}
                    />
                    {t("options")}
                  </>
                }
                onClose={() => toggleSpeedDialogVisibility(false)}
                onOpen={() => toggleSpeedDialogVisibility(true)}
                open={isSpeedDialogVisible}
                FabProps={{
                  variant: "extended",
                }}
              >
                <SpeedDialAction
                  icon={<NoteAddIcon />}
                  tooltipTitle={t("addNewDocuments")}
                  tooltipOpen
                  onClick={openUploadDialog}
                />
                <SpeedDialAction
                  icon={<ShareIcon />}
                  tooltipTitle={t("shareContract")}
                  tooltipOpen
                  onClick={openShareContractDialog}
                />
              </SpeedDial>
            </Zoom>

            <DocumentsUploadDialog
              open={isUploadDialaogVisible}
              onClose={() => toggleUploadDiaologVisibility(false)}
              onNewFilesUploaded={getContract}
              contract={contract}
              disableRestoreFocus
              fileNamesToIgnore={files.map((file) => file.name.split("_")[1])}
            />

            <ShareContractDialog
              contract={contract}
              open={isShareContractDialogVisible}
              onClose={() => toggleShareContractDiaologVisibility(false)}
              customer={contract.get("customer")}
              toggleSmsModalVisibility={toggleSmsModalVisibility}
              disableRestoreFocus
            />

            <SendSmsDialog
              open={isSmsModalVisible}
              contract={contract}
              onClose={toggleSmsModalVisibility}
            />
          </>
        )}
      </Page>
    </ContractProvider>
  );
};

export default ContractDetails;
