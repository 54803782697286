import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { Backdrop, CircularProgress } from "@mui/material";
import * as FilePond from "filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";
import { SnackbarProvider } from "notistack";
import Parse from "parse";
import React from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "./app/store";
import ScrollToTop from "./components/ScrollToTop";
import "./i18n";
import Router from "./routes";
import * as serviceWorker from "./serviceWorker";
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";

FilePond.registerPlugin(FilePondPluginFileValidateType);

const PARSE_APPLICATION_ID = process.env.REACT_APP_PARSE_APPLICATION_ID;
const PARSE_HOST_URL = process.env.REACT_APP_PARSE_HOST_URL;
const PARSE_JAVASCRIPT_KEY = process.env.REACT_APP_PARSE_JAVASCRIPT_KEY;

if (
  [PARSE_APPLICATION_ID, PARSE_HOST_URL, PARSE_JAVASCRIPT_KEY].every(
    (conf) => conf !== undefined
  )
) {
  Parse.initialize(PARSE_APPLICATION_ID!, PARSE_JAVASCRIPT_KEY!);
  Parse.serverURL = PARSE_HOST_URL!;
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeConfig>
        <ScrollToTop />
        <GlobalStyles />
        <HelmetProvider>
          <Provider store={store}>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              preventDuplicate
            >
              <React.Suspense
                fallback={
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open
                  >
                    <CircularProgress />
                  </Backdrop>
                }
              >
                <Router />
              </React.Suspense>
            </SnackbarProvider>
          </Provider>
        </HelmetProvider>
      </ThemeConfig>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
