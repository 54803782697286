// ----------------------------------------------------------------------
import type { Theme } from "@mui/material";

const Drawer = (theme: Theme) => ({
  MuiDrawer: {
    styleOverrides: {
      paper: {
        [theme.breakpoints.up("md")]: {
          borderTopRightRadius: 45,
          borderTopLeftRadius: 45,
          maxWidth: "90vw",
          margin: "0 auto",
        },
      },
    },
  },
});

export default Drawer;
