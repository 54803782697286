import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import FilterListIcon from "@mui/icons-material/FilterList";
import { LoadingButton } from "@mui/lab";
import {
  Badge,
  Box,
  Container,
  Fab,
  FormControlLabel,
  Grid,
  Grow,
  Skeleton,
  Stack,
  styled,
  Switch,
  Typography,
} from "@mui/material";
import * as dateFns from "date-fns";
import { addDays } from "date-fns";
import FileSaver from "file-saver";
import Parse from "parse";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAsync, useAsyncFn, useToggle } from "react-use";
import { TContract } from "../../types/Contract";
import { getRandomInt } from "../../utils";
import ContractCard from "../ContractCard";
import DocumentListFilter from "../DocumentListFilter";
import Page from "../Page";

const StyledStack = styled(Stack)(({ theme }) => ({
  position: "fixed",
  right: 15,
  [theme.breakpoints.down("md")]: {
    top: `calc(${theme.appBar.md}px + 48px)`,
  },
  [theme.breakpoints.up("md")]: {
    top: `calc(${theme.appBar.lg}px + 48px)`,
  },
}));

const DocumentListLoader: React.FC = () => {
  const loader = new Array(getRandomInt(2, 8))
    .fill(undefined)
    .map((_, index) => (
      <Grid
        item
        xs={1}
        sm={2}
        md={4}
        lg={4}
        key={`document-card-content-loader-${index}`}
      >
        <Box px={3} mt={3}>
          <Skeleton variant="text" width={"75%"} height={30} />

          <Box mt={3}>
            <Stack spacing={1} width="100%">
              <Stack
                direction="row"
                width="100%"
                spacing={2}
                alignItems="center"
              >
                <Skeleton variant="circular" width={48} height={40} />

                <Stack width="100%" spacing="2px">
                  <Skeleton variant="text" width={"50%"} />

                  <Skeleton variant="text" width={"35%"} />

                  <Skeleton variant="rectangular" height={2} width="90%" />
                </Stack>
              </Stack>

              <Stack
                direction="row"
                width="100%"
                spacing={2}
                alignItems="center"
              >
                <Skeleton variant="circular" width={48} height={40} />

                <Stack width="100%" spacing="2px">
                  <Skeleton variant="text" width={"50%"} />

                  <Skeleton variant="text" width={"20%"} />

                  <Skeleton variant="text" width={"35%"} />

                  <Skeleton variant="rectangular" height={2} width="90%" />
                </Stack>
              </Stack>

              <Stack
                direction="row"
                width="100%"
                spacing={2}
                alignItems="center"
              >
                <Skeleton variant="circular" width={48} height={40} />

                <Stack width="100%" spacing="2px">
                  <Skeleton variant="text" width={"50%"} />

                  <Skeleton variant="text" width={"20%"} />

                  <Skeleton variant="rectangular" height={2} width="90%" />
                </Stack>
              </Stack>

              <Stack
                direction="row"
                width="100%"
                spacing={2}
                alignItems="center"
              >
                <Skeleton variant="circular" width={48} height={40} />

                <Stack width="100%" spacing="2px">
                  <Skeleton variant="text" width={"50%"} />

                  <Skeleton variant="text" width={"20%"} />

                  <Skeleton variant="rectangular" height={2} width="90%" />
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Box>

        <Skeleton
          variant="rectangular"
          height={25}
          sx={{
            bgcolor: "transparent",
          }}
        />

        <Stack direction="row" spacing={1} px={2}>
          <Skeleton variant="text" width={"5%"} />

          <Skeleton variant="text" width={"45%"} />
        </Stack>
      </Grid>
    ));

  return <>{loader}</>;
};

const DocumentList: React.FC = () => {
  const { REACT_APP_NAME } = process.env;
  const { t } = useTranslation();

  const [showExpiringSoon, toggleShowExpiringSoon] = useToggle(true);

  const [filters, setFilters] = React.useState<string | null>(null);
  const [contracts, setContracts] = React.useState<Parse.Object<TContract>[]>(
    []
  );
  const [isLoadingContracts, setIsLoadingContracts] =
    React.useState<boolean>(false);

  const [areFiltersVisibile, toggleFiltersVisibility] = useToggle(false);

  const renderDocuments = () => {
    const { REACT_APP_NO_RESULTS_IMAGE_URL } = process.env;

    if (contracts.length !== 0) {
      return contracts.map((contract, index) => (
        <Grid item xs={1} sm={2} md={4} lg={4} key={`contract-${index}`}>
          <ContractCard contract={contract} />
        </Grid>
      ));
    }

    return (
      <Grid
        item
        xs={12}
        alignItems={"center"}
        justifyContent="center"
        gap={2}
        display="flex"
        flexDirection="column"
      >
        <img
          alt=""
          src={REACT_APP_NO_RESULTS_IMAGE_URL}
          style={{
            maxHeight: 400,
          }}
        />

        <Typography variant="overline">{t("noResults")}</Typography>
      </Grid>
    );
  };

  useAsync(async () => {
    let contractsQuery = new Parse.Query<Parse.Object<TContract[]>>(
      process.env.REACT_APP_CONTRACT_TABLE_NAME!
    )
      // @ts-ignore
      .equalTo("user", Parse.User.current()!)
      
    
    if (REACT_APP_NAME === "Sign&Travel") {
      // @ts-ignore
      contractsQuery.addAscending("customer.signDate");
    }
    else {
      contractsQuery.addDescending("createdAt");
    }

    if (showExpiringSoon) {
      const today = new Date();

      if (REACT_APP_NAME === "Sign&Gass") {
        today.setHours(0, 0, 0, 0);

        contractsQuery
          // @ts-ignore
          .greaterThanOrEqualTo("event.date", today)
          // @ts-ignore
          .lessThan("event.date", addDays(today, 8));
      }

      if (REACT_APP_NAME === "Sign&Travel") {
        const alreadyExpiredNotSignedContractsQuery = new Parse.Query<Parse.Object<TContract[]>>(
      process.env.REACT_APP_CONTRACT_TABLE_NAME!
        )
        
        const expiringNextWeekContractsQuery = new Parse.Query<Parse.Object<TContract[]>>(
      process.env.REACT_APP_CONTRACT_TABLE_NAME!
        )

        const alreadyExpiredNotSignedContractsWithoutSignDateQuery = new Parse.Query<Parse.Object<TContract[]>>(
      process.env.REACT_APP_CONTRACT_TABLE_NAME!
        )

        const expiringNextWeekContractsWithoutSignDateQuery = new Parse.Query<Parse.Object<TContract[]>>(
      process.env.REACT_APP_CONTRACT_TABLE_NAME!
        )

        alreadyExpiredNotSignedContractsQuery.lessThanOrEqualTo(
          // @ts-ignore
          "customer.signDate",
          dateFns.startOfDay(new Date())
        ).doesNotExist(
          //@ts-ignore
          "signed");

        expiringNextWeekContractsQuery
          .greaterThanOrEqualTo(
            // @ts-ignore
            "customer.signDate",
            dateFns.startOfDay(new Date())
          )
          // @ts-ignore
          .lessThan("customer.signDate", addDays(today, 7));
        
        alreadyExpiredNotSignedContractsWithoutSignDateQuery
        // @ts-ignore
        .equalTo("customer.signDate", null)
        // .doesNotExist("customer.signDate")
        .lessThanOrEqualTo(
          // @ts-ignore
          "customer.departureDate",
          dateFns.startOfDay(new Date())
        ).doesNotExist(
          //@ts-ignore
          "signed");

        expiringNextWeekContractsWithoutSignDateQuery
         // @ts-ignore
          .equalTo("customer.signDate", null)
        // .doesNotExist("customer.signDate")
         .greaterThanOrEqualTo(
            // @ts-ignore
            "customer.departureDate",
            dateFns.startOfDay(new Date())
          )
          // @ts-ignore
          .lessThan("customer.departureDate", addDays(today, 7));
        
        contractsQuery = Parse.Query.or(alreadyExpiredNotSignedContractsQuery, expiringNextWeekContractsQuery, alreadyExpiredNotSignedContractsWithoutSignDateQuery, expiringNextWeekContractsWithoutSignDateQuery);       
        // @ts-ignore
        contractsQuery.addAscending("customer.signDate");
      }
    }
    try {
      setIsLoadingContracts(true);

      setContracts(
        // @ts-ignore
        await contractsQuery.find()
      );
    } finally {
      setIsLoadingContracts(false);
    }
  }, [showExpiringSoon]);

  const [
    { loading: isDownloadingContracts, value: downloadedContracts },
    downloadContracts,
  ] = useAsyncFn(async () => {
    if (filters) {
      return Parse.Cloud.run("zipContracts", filters);
    }

    return Promise.reject();
  }, [filters]);

  React.useEffect(() => {
    if (filters) {
      toggleShowExpiringSoon(false);
    }
  }, [filters, toggleShowExpiringSoon]);
  
  React.useEffect(() => {
    if (downloadedContracts) {
      FileSaver.saveAs(downloadedContracts.url(), "signed_contracts.zip");
    }
  }, [downloadedContracts]);

  return (
    <Page title="Storico documenti">
      <Container
        sx={() => ({
          paddingBottom: 5,
        })}
      >
        <Grid
          container
          spacing={{ xs: 2, md: 2 }}
          columns={{
            xs: 1,
            sm: 4,
            md: 12,
            lg: 12,
          }}
          pt={19}
          pb={12}
          sx={{
            ml: "0 !important",
          }}
        >
          {isLoadingContracts ? <DocumentListLoader /> : renderDocuments()}
        </Grid>
      </Container>

      <Grow
        in
        timeout={{
          enter: 1100,
        }}
      >
        <StyledStack direction="row" gap={2} mt={4}>
          <LoadingButton
            variant="text"
            startIcon={<DownloadForOfflineIcon />}
            loading={isDownloadingContracts}
            onClick={downloadContracts}
            disabled={contracts.length === 0}
          >
            {t("downloadContracts")}
          </LoadingButton>

          <FormControlLabel
            control={
              <Switch
                checked={showExpiringSoon}
                disabled={filters !== null}
                onChange={toggleShowExpiringSoon}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={t("expiringContracts") as string}
          />
        </StyledStack>
      </Grow>

      <Grow
        in
        timeout={{
          enter: 1100,
        }}
      >
        <Fab
          variant="extended"
          disabled={isLoadingContracts}
          sx={{
            position: "fixed",
            bottom: 40,
            right: 15,
          }}
          onClick={toggleFiltersVisibility}
        >
          <Badge
            color="secondary"
            variant="dot"
            componentsProps={{
              badge: {
                style: {
                  visibility: filters !== null ? "visible" : "hidden",
                  transform: "translate(12px, -13px) scale(1.5)",
                },
              },
            }}
          >
            <FilterListIcon sx={{ mr: 1 }} />
            {t("filterContracts")}
          </Badge>
        </Fab>
      </Grow>

      <DocumentListFilter
        open={areFiltersVisibile}
        onClose={() => toggleFiltersVisibility(false)}
        setContracts={setContracts}
        isLoadingContracts={isLoadingContracts}
        setIsLoadingContracts={setIsLoadingContracts}
        setFilters={setFilters}
      />
    </Page>
  );
};

export default DocumentList;
