import { alpha, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import HistoryIcon from "@mui/icons-material/History";
import UploadFileIcon from "@mui/icons-material/UploadFile";

import Page from "../../components/Page";
import TabPanel from "../../components/TabPanel";

import { ContractProvider } from "../../components/ContractContext";
import DocumentsList from "../../components/DocumentList";
import DocumentsUploadStepper from "../../components/DocumentsUploadStepper/DocumentsUploadStepper";

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

enum TabsContent {
  UPLOAD,
  HISTORY,
}

const DocumentsHistory: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [current, setTab] = React.useState<TabsContent>(TabsContent.UPLOAD);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (_: React.SyntheticEvent, tab: TabsContent) => {
    setTab(tab);
  };

  return (
    <Page title="Gestione Documenti">
      <Tabs
        value={current}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        aria-label="Esegui l'upload di un nuovo documento oppure visualizza lo storico"
        variant={isMobile ? "scrollable" : "fullWidth"}
        centered={!isMobile}
        scrollButtons="auto"
        sx={{
          position: "sticky",
          [theme.breakpoints.down("md")]: {
            top: theme.appBar.md,
          },
          [theme.breakpoints.up("md")]: {
            top: theme.appBar.lg,
          },
          boxShadow: "0px 20px 20px rgb(0 0 0 /7%)",
          backdropFilter: "blur(6px)",
          WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
          backgroundColor: alpha(theme.palette.background.default, 0.72),
          zIndex: 1,
        }}
      >
        <Tab
          icon={<UploadFileIcon />}
          iconPosition="start"
          label={t("uploadFilesTab")}
          {...a11yProps(TabsContent.UPLOAD)}
        />

        <Tab
          icon={<HistoryIcon />}
          iconPosition="start"
          label={t("documentsArchiveTab")}
          {...a11yProps(TabsContent.HISTORY)}
        />
      </Tabs>

      <ContractProvider>
        <TabPanel
          value={current}
          index={TabsContent.HISTORY}
          dir={theme.direction}
          sx={{
            height: "calc(100vh)",
          }}
        >
          <DocumentsList />
        </TabPanel>

        <TabPanel
          value={current}
          index={TabsContent.UPLOAD}
          dir={theme.direction}
          sx={{
            height: "calc(100vh - 72px)",
          }}
        >
          <DocumentsUploadStepper />
        </TabPanel>
      </ContractProvider>
    </Page>
  );
};

export default DocumentsHistory;
