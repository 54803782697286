import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: true,
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: process.env.REACT_APP_TRANSLATION_FILE_PATH,
    },
    supportedLngs: ["it", "en"],
    fallbackLng: "it",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
