import { Dialog, DialogContent } from "@mui/material";
import Parse from "parse";
import React from "react";

import type { DialogProps } from "@mui/material";

import { ICustomer } from "../types/Customer";
import ShareContract from "./ShareContract";

interface IContractSendDialog extends DialogProps {
  contract: Parse.Object<Parse.Attributes>;
  customer: ICustomer;
  toggleSmsModalVisibility: () => void;
}

const ShareContractDialog: React.FC<IContractSendDialog> = ({
  open,
  onClose,
  contract,
  customer,
  toggleSmsModalVisibility,
  ...other
}) => (
  <Dialog
    maxWidth="sm"
    fullWidth
    open={open}
    {...other}
    // @ts-ignore
    onBackdropClick={onClose}
  >
    <DialogContent dividers>
      <ShareContract
        isCreatingContract={false}
        contract={contract}
        customer={customer}
        resetStepper={() => {}}
        toggleSmsModalVisibility={toggleSmsModalVisibility}
        showButtons={false}
      />
    </DialogContent>
  </Dialog>
);

export default ShareContractDialog;
