// material
import React from "react";
import { Box, BoxProps } from "@mui/material";

const Logo: React.FC<BoxProps> = ({ sx }) => {
  const { REACT_APP_LOGO_URL } = process.env;

  return <Box component="img" src={REACT_APP_LOGO_URL} sx={{ ...sx }} />;
};

export default Logo;
